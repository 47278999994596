import { useEffect } from "react";
import { useRouter } from "next/router";

import { extractRoleInfo } from "../../lib/api";
import { UserType } from "../../types/api";

export const useSecureRoute = (user: UserType) => {
  const router = useRouter();

  useEffect(() => {
    // Don't run checks if user data isn't loaded yet
    if (!user) return;

    // Extract role information using the existing utility function
    const {
      roleType,
      enterpriseId: userEnterpriseId,
      organisationId: userOrgId,
    } = extractRoleInfo(user);

    // Get all enterprises this user has access to
    const accessibleEnterpriseIds =
      user.userRoles
        ?.map((userRole) => userRole.enterpriseId)
        .filter(Boolean) || [];

    // Get URL parameters
    const urlEnterpriseId = Number(router.query.enterpriseId);
    const urlOrgId = Number(router.query.organisationId);

    // Get organization data if applicable
    const organisationRole = user.userRoles?.find(
      (role) => role.organisationId === userOrgId
    );
    const orgEnterpriseId = organisationRole?.organisation?.enterpriseId;

    // Check if user has access to the enterprise in the URL
    const hasAccessToUrlEnterprise =
      accessibleEnterpriseIds.includes(urlEnterpriseId);

    // Handle enterprise admin permissions
    if (roleType === "ENTERPRISE_ADMIN") {
      const isAccessingEnterpriseRoot = router.asPath.includes(
        "/enterpriseDashboard/"
      );
      const isAccessingInvalidEnterprise =
        !Number.isNaN(urlEnterpriseId) && !hasAccessToUrlEnterprise;

      if (
        (isAccessingEnterpriseRoot && !hasAccessToUrlEnterprise) ||
        isAccessingInvalidEnterprise
      ) {
        router.push("/unauthorized");
      }
    }
    // Handle organization admin permissions
    else if (roleType === "ORGANISATION_ADMIN") {
      const isAccessingWrongEnterprise =
        userEnterpriseId !== undefined &&
        !Number.isNaN(urlEnterpriseId) &&
        orgEnterpriseId !== urlEnterpriseId;

      const isAccessingWrongOrg =
        userOrgId !== undefined &&
        !Number.isNaN(urlOrgId) &&
        userOrgId !== urlOrgId;

      const isAccessingEnterpriseRoot = /^\/enterpriseDashboard\/\d+\/$/.test(
        router.asPath
      );

      if (
        isAccessingWrongEnterprise ||
        isAccessingWrongOrg ||
        isAccessingEnterpriseRoot
      ) {
        router.push("/unauthorized");
      }
    }
  }, [user, router]);
};
