/* eslint-disable no-param-reassign */
import path from "path";

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";
import { getAuth, signOut } from "firebase/auth"; // Add this import
import getConfig from "next/config";
import router from "next/router";
import { parseCookies, setCookie } from "nookies";

export class CoreAPI {
  baseURL: string;

  proxyURL: string;

  token: string | null;

  axiosInstance: AxiosInstance;

  private refreshTokenPromise: Promise<string> | null = null;

  private isServer: boolean;

  constructor() {
    const { publicRuntimeConfig } = getConfig();
    this.baseURL = publicRuntimeConfig.API_BASE_URL;
    this.proxyURL = publicRuntimeConfig.API_PROXY_URL;
    this.token = null;
    this.axiosInstance = axios.create();
    this.isServer = typeof window === "undefined";

    this.axiosInstance.interceptors.request.use(this.authorizationInterceptor);
  }

  // only for the server-side to set it from context
  setToken = (token: string | null) => {
    this.token = token;
    if (token) {
      setCookie(null, "token", token, {
        maxAge: 60 * 60 * 24 * 365, // 1 year
        path: "/",
      });
    }
  };

  private async refreshFirebaseToken(): Promise<string> {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error("No user logged in");
    }

    try {
      const newToken = await user.getIdToken(true);
      this.setToken(newToken);
      return newToken;
    } catch (error) {
      console.error("Failed to refresh Firebase token:", error);
      throw error;
    }
  }

  refreshToken = async (): Promise<string> => {
    if (!this.refreshTokenPromise) {
      this.refreshTokenPromise = this.refreshFirebaseToken().finally(() => {
        this.refreshTokenPromise = null;
      });
    }
    return this.refreshTokenPromise;
  };

  getToken = (): string | null => {
    if (this.isServer) {
      return this.token;
    }

    const { token } = parseCookies();
    return token || null;
  };

  authorizationInterceptor = async (
    config: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> => {
    const newConfig = { ...config };

    let token = this.getToken();

    if (token) {
      try {
        // Check if token is expired
        const tokenParts = token.split(".");
        if (tokenParts.length !== 3) {
          // Instead of throwing an error, we'll try to refresh the token
          token = await this.refreshToken();
        } else {
          const tokenData = JSON.parse(
            Buffer.from(tokenParts[1], "base64").toString()
          );
          const expirationTime = tokenData.exp * 1000; // Convert to milliseconds
          const currentTime = Date.now();

          if (currentTime >= expirationTime - 60000) {
            // Refresh if within 1 minute of expiration
            token = await this.refreshToken();
          }
        }

        newConfig.headers.Authorization = `Bearer ${token}`;
      } catch (error) {
        console.error("Error processing token:", error);
        // If there's an error processing the token, try to refresh it
        token = await this.refreshToken();
        newConfig.headers.Authorization = `Bearer ${token}`;
      }
    }

    return newConfig;
  };

  private handleApiError = async (error: any) => {
    if (error.response && error.response.status === 401) {
      // 401 Unauthorized - likely an expired token issue
      try {
        const newToken = await this.refreshToken();
        // Retry the original request with the new token
        const { config } = error;
        config.headers.Authorization = `Bearer ${newToken}`;
        return await this.axiosInstance.request(config);
      } catch (refreshError) {
        // If refresh fails, sign out the user
        const auth = getAuth();
        await signOut(auth);
        sessionStorage.clear();
        router.push("/");
        throw new Error("Session expired. Please log in again.");
      }
    } else if (error.response && error.response.status === 403) {
      // 403 Forbidden - this is a legitimate permissions issue
      // Redirect to the unauthorized page
      throw error;
    }
    throw error;
  };

  getAbsoluteUrl = (url: string) => {
    const baseUrl = this.isServer ? this.baseURL : this.proxyURL;
    const { origin, pathname } = new URL(baseUrl);
    return new URL(path.join(pathname, url), origin).toString();
  };

  get = async (relativeUrl: string, config?: AxiosRequestConfig<any>) => {
    const url = this.getAbsoluteUrl(relativeUrl);
    try {
      return await this.axiosInstance.get(url, config);
    } catch (error) {
      return this.handleApiError(error);
    }
  };

  post = async (
    relativeUrl: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig<any>
  ) => {
    const url = this.getAbsoluteUrl(relativeUrl);
    try {
      const response = await this.axiosInstance.post(url, data, config);
      return response;
    } catch (error) {
      return this.handleApiError(error);
    }
  };

  patch = async (
    relativeUrl: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig<any>
  ) => {
    const url = this.getAbsoluteUrl(relativeUrl);
    try {
      const response = await this.axiosInstance.patch(url, data, config);
      return response;
    } catch (error) {
      return this.handleApiError(error);
    }
  };

  put = async (
    relativeUrl: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig<any>
  ) => {
    const url = this.getAbsoluteUrl(relativeUrl);
    try {
      const response = await this.axiosInstance.put(url, data, config);
      return response;
    } catch (error) {
      return this.handleApiError(error);
    }
  };

  delete = async (relativeUrl: string, config?: AxiosRequestConfig<any>) => {
    const url = this.getAbsoluteUrl(relativeUrl);
    try {
      const response = await this.axiosInstance.delete(url, config);
      return response;
    } catch (error) {
      return this.handleApiError(error);
    }
  };
}

const coreAPI = new CoreAPI();

export default coreAPI;
